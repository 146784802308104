import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppDispatch,
  getDeliveryState,
  getIsEdo,
  getIsEdoFlow,
  getIsOffer,
  getOrder,
} from 'store';
import { logOutUser } from 'store/auth.slice';
import {
  deliveryPriceReset,
  setDeliveryAddressError,
  setDeliveryData,
  setDeliveryFormReady,
  setTariffLoading,
} from 'store/delivery.slice';
import { checkEdo } from 'store/isEdo.slice';
import { checkDocFlow } from 'store/isEdoFlow.slice';
import { checkOffer } from 'store/isOffer.slice';
import { showLoader } from 'store/loader.slice';
import { showModal } from 'store/modal.slice';
import { setOrders } from 'store/orders.slice';
import { addAlert } from 'store/pageAlerts.slice';
import { getProducts } from 'store/product.slice';
import { setSuccessOrder } from 'store/successOrder.slice';
import {
  createOrderUrl,
  dadataUrl,
  deliveryUrl,
  getCreatedOrders,
} from 'urls';
import { PICKUP_ADRESS, currency } from 'utils';

import AutocompleteList from 'components/autocomplete-list/autocomplete-list';
import CustomInput from 'components/custom-input/custom-input';
import CustomSelect from 'components/custom-select';
import SwitchTabs from 'components/switch-tabs/switch-tabs';

import { usePartner } from 'shared/hooks/use-partner.hook';
import { IAddress } from 'shared/models/dadata.model';
import {
  IOrderBody,
  IOrderLine,
  Order,
} from 'shared/models/order.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { metrika } from 'shared/utils/metrika.util';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import bagIcon from 'images/icons/bag.svg';
import linkIcon from 'images/icons/transition.svg';
import truckIcon from 'images/icons/truck.svg';

import { INPUT_ERROR } from './order-delivery.const';
import styles from './order-delivery.module.css';
import { checkPhone, hasLength } from './order-delivery.utils';

const inputMask = (e: ChangeEvent<HTMLInputElement>) => {
  if (
    e.target.value.substring(0, 2) === '+7' ||
    e.target.value.substring(0, 1) === '7' ||
    (e.target.value.substring(0, 1) === '8' &&
      e.target.value.substring(0, 3) !== '800')
  ) {
    const patternForFullTelephone =
      /(\+7|8|7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForFullTelephone,
      '+7 ($2) $3-$4-$5',
    ); // пробуем замену если есть  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = correctNumber);
  }
  if (
    e.target.value.substring(0, 1) === '9' ||
    e.target.value.substring(0, 3) === '800'
  ) {
    const patternForTelephone =
      /[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForTelephone,
      '($1) $2-$3-$4',
    ); // пробуем замену если нет  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = `+7 ${correctNumber}`);
  }

  return false;
};

const hideListDelay = 200;

const orderBodyTemplate = {
  orderInfo: {
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    comment: '',
    fiasId: '',
    vendorId: '',
    vendorName: '',
    urgentTypeId: '',
    tariffName: '',
    insurance: '',
    period: '',
    address: '',
    addressFiasId: '',
    cityFiasId: '',
    settlementFiasId: '',
    regionFiasId: '',
    country: '',
    countryIsoCode: '',
    postalCode: '',
    region: '',
    regionType: '',
    city: '',
    settlement: '',
  },
  orderLines: [],
};

interface IOrderDeliveryProps {
  startOrder: boolean;
  startOrderOff: () => void;
  aside: boolean;
  onOrderLoaderFlagToggle: (val: boolean) => void;
}

const OrderDelivery: FC<IOrderDeliveryProps> = memo(
  ({ startOrder, startOrderOff, aside, onOrderLoaderFlagToggle }) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = tokens.getAccess();

    const { order } = useSelector(getOrder);
    const orgId = multiLoginStorage.getOrgId();

    const {
      deliveryData,
      isTariffLoading,
      isDeliveryFormWasEdit,
      isDeliveryAddressError,
    } = useSelector(getDeliveryState);

    const { isEdoFlow } = useSelector(getIsEdoFlow);
    const { isEdoRegistered } = useSelector(getIsEdo);
    const { isOfferAccepted } = useSelector(getIsOffer);
    const isPartner = usePartner();

    const [list, setList] = useState([]);
    const [focusAddress, setFocusAddress] = useState(false);
    const [tariffErrorText, setTariffErrorText] = useState('');
    const [addressErrorText, setAddressErrorText] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [listShow, setListShow] = useState(false);
    const [searchAddress, setSearchAddress] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [nameError, setNameError] = useState(false);
    const [phoneErrorText, setPhoneErrorText] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [isLoadingAdres, setIsLoadingAdres] = useState(false);
    const [isClearDelivery, setIsClearDelivery] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(
      deliveryData.currentAddress,
    );
    const [comments, setComments] = useState(deliveryData.comments);
    const [contactName, setContactName] = useState(
      deliveryData.contactName,
    );
    const [contactPhone, setContactPhone] = useState(
      deliveryData.contactPhone,
    );

    const clearPhoneError = useCallback(() => {
      setPhoneError(false);
      setPhoneErrorText('');
    }, []);

    const clearNameError = useCallback(() => {
      setNameError(false);
      setNameErrorText('');
    }, []);

    const clearAddressError = useCallback(() => {
      setAddressError(false);
      setAddressErrorText('');
    }, []);

    const clearTariffError = useCallback(() => {
      setTariffErrorText('');
    }, []);

    const clearErrors = useCallback(() => {
      clearPhoneError();
      clearNameError();
      clearAddressError();
      clearTariffError();
    }, [
      clearAddressError,
      clearNameError,
      clearPhoneError,
      clearTariffError,
    ]);

    const checkName = useCallback(
      () => hasLength(deliveryData.contactName),
      [deliveryData.contactName],
    );

    const checkPhoneLength = useCallback(
      () => hasLength(deliveryData.contactPhone),
      [deliveryData.contactPhone],
    );

    const checkRegExpPhoneNumber = useCallback(
      () => checkPhone(deliveryData.contactPhone),
      [deliveryData.contactPhone],
    );

    const checkAddress = useCallback(
      () => deliveryData.fiasId !== null,
      [deliveryData.fiasId],
    );

    const checkTariff = useCallback(
      () => deliveryData.tariff !== null,
      [deliveryData.tariff],
    );

    const changeComments = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        setComments(e.target.value);
      },
      [],
    );

    const changeName = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        clearNameError();
        setContactName(e.target.value);
      },
      [clearNameError],
    );

    const changePhone = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        clearPhoneError();
        inputMask(e);
        setContactPhone(e.target.value);
      },
      [clearPhoneError],
    );

    const handlePhoneFocus = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value.startsWith('+7 ')) {
          e.target.value = '+7 ';
        }
      },
      [],
    );

    const clearTariffs = useCallback(() => {
      dispatch(
        setDeliveryData({
          ...deliveryData,
          deliveryList: [],
          tariff: null,
          deliveryPrice: 0,
        }),
      );
      // eslint-disable-next-line
    }, [
      deliveryData.deliveryList,
      deliveryData.tariff,
      deliveryData.deliveryPrice,
      dispatch,
    ]);

    const hideList = useCallback(() => {
      setList([]);
      setFocusAddress(false);
      setSearchAddress(false);
    }, []);

    const hideListAfterDelay = useCallback(() => {
      setTimeout(() => hideList(), hideListDelay);
    }, [hideList]);

    const changeCurrentAddress = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        clearAddressError();
        setSearchAddress(true);
        setCurrentAddress(e.target.value);
      },
      [clearAddressError],
    );

    const changeCurrentAddressWithoutSearching = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setFocusAddress(true);
        setCurrentAddress(e.target.value);
      },
      [],
    );

    const changeCurrentAddressFromSuggest = useCallback(
      (item: IAddress) => {
        if (!item) return;

        clearAddressError();
        setIsClearDelivery(false);
        clearTariffs();
        dispatch(
          setDeliveryData({
            ...deliveryData,
            start: true,
            currentAddress: item.value,
            fiasId: item.data.fiasId,
            dadataInfo: item.data,
          }),
        );
        hideList();
        setCurrentAddress(item.value);
      },
      // eslint-disable-next-line
      [
        clearAddressError,
        deliveryData.start,
        deliveryData.currentAddress,
        deliveryData.fiasId,
        deliveryData.dadataInfo,
        dispatch,
        hideList,
        clearTariffs,
      ],
    );

    const onClear = useCallback(() => {
      setIsClearDelivery(true);
      clearTariffs();
    }, [clearTariffs]);

    const changeTariff = useCallback(
      (e: string) => {
        const tariff = deliveryData.deliveryList.filter(
          (item) => item.urgentTypeId === e,
        )[0];
        clearTariffError();
        dispatch(
          setDeliveryData({
            ...deliveryData,
            tariff,
            start: false,
            deliveryPrice: tariff?.price,
            contactName,
            contactPhone,
            comments,
          }),
        );
      }, // eslint-disable-next-line
      [
        clearTariffError,
        deliveryData.tariff,
        deliveryData.tariff?.price,
        deliveryData.start,
        deliveryData.deliveryList,
        contactName,
        contactPhone,
        comments,
        dispatch,
      ],
    );

    const validateAddress = useCallback(() => {
      let isValid = true;
      let errorText = '';

      if (deliveryData.deliveryType === 'Доставка') {
        if (!checkAddress()) {
          isValid = false;
          errorText = INPUT_ERROR.noEmptyCity;
        }
      }

      if (!isValid) {
        setAddressErrorText(errorText);
      }

      setAddressError(!isValid);

      return isValid;
    }, [deliveryData.deliveryType, checkAddress]);

    const validateName = useCallback(() => {
      const isValid = checkName();
      if (!isValid) setNameErrorText(INPUT_ERROR.setName);
      setNameError(!isValid);
      return isValid;
    }, [checkName]);

    const validateTariff = useCallback(() => {
      let isValid;

      if (deliveryData.deliveryType === 'Доставка') {
        isValid = checkTariff();
      } else {
        isValid = true;
      }

      if (!isValid) {
        setTariffErrorText(INPUT_ERROR.noTariff);
      }
      return isValid;
    }, [deliveryData.deliveryType, checkTariff]);

    const validatePhone = useCallback(() => {
      if (!checkPhoneLength()) {
        setPhoneError(true);
        setPhoneErrorText(INPUT_ERROR.setPhone);
        return false;
      }

      const isValid = checkPhoneLength() && checkRegExpPhoneNumber();
      if (!isValid) setPhoneErrorText(INPUT_ERROR.inCorrectPhone);
      setPhoneError(!isValid);

      return isValid;
    }, [checkPhoneLength, checkRegExpPhoneNumber]);

    const validateOrder = useCallback(() => {
      const isPasswordValid = validateName();
      const isLoginValid = validatePhone();
      const isAddressValid = validateAddress();
      const isTariffValid = isPartner ? validateTariff() : true;

      return (
        isLoginValid &&
        isPasswordValid &&
        isAddressValid &&
        isTariffValid
      );
    }, [
      isPartner,
      validateAddress,
      validateName,
      validatePhone,
      validateTariff,
    ]);

    const formatPhoneForOrder = useCallback(() => {
      const phoneOnlyNumber = deliveryData.contactPhone.replace(
        /\D+/g,
        '',
      );
      const phoneOnlyNumberArray = phoneOnlyNumber.split('');
      return (
        '8' +
        `-${phoneOnlyNumberArray[1]}${phoneOnlyNumberArray[2]}${phoneOnlyNumberArray[3]}-${phoneOnlyNumberArray[4]}${phoneOnlyNumberArray[5]}${phoneOnlyNumberArray[6]}${phoneOnlyNumberArray[7]}-${phoneOnlyNumberArray[8]}${phoneOnlyNumberArray[9]}${phoneOnlyNumberArray[10]}`
      );
    }, [deliveryData.contactPhone]);

    const fillOrderBody = useCallback(() => {
      const orderBodyTemplateCopy = { ...orderBodyTemplate };
      orderBodyTemplateCopy.orderInfo.contactName =
        deliveryData.contactName;
      orderBodyTemplateCopy.orderInfo.contactPhone =
        formatPhoneForOrder();
      orderBodyTemplateCopy.orderInfo.comment = deliveryData.comments;
      orderBodyTemplateCopy.orderInfo.fiasId =
        deliveryData.fiasId ?? '';
      if (
        deliveryData.deliveryType === 'Доставка' &&
        deliveryData.tariff &&
        deliveryData.dadataInfo
      ) {
        orderBodyTemplateCopy.orderInfo.vendorId =
          deliveryData.tariff.vendorId;
        orderBodyTemplateCopy.orderInfo.vendorName =
          deliveryData.tariff.vendorName;
        orderBodyTemplateCopy.orderInfo.urgentTypeId =
          deliveryData.tariff.urgentTypeId;
        orderBodyTemplateCopy.orderInfo.tariffName =
          deliveryData.tariff.tariffName;
        orderBodyTemplateCopy.orderInfo.insurance =
          deliveryData.tariff.insurance;
        orderBodyTemplateCopy.orderInfo.period =
          deliveryData.tariff.period;
        orderBodyTemplateCopy.orderInfo.address =
          deliveryData.currentAddress;
        orderBodyTemplateCopy.orderInfo.addressFiasId =
          deliveryData.dadataInfo.fiasId;
        orderBodyTemplateCopy.orderInfo.cityFiasId =
          deliveryData.dadataInfo.cityFiasId;
        orderBodyTemplateCopy.orderInfo.settlementFiasId =
          deliveryData.dadataInfo.settlementFiasId;
        orderBodyTemplateCopy.orderInfo.regionFiasId =
          deliveryData.dadataInfo.regionFiasId;
        orderBodyTemplateCopy.orderInfo.country =
          deliveryData.dadataInfo.country;
        orderBodyTemplateCopy.orderInfo.countryIsoCode =
          deliveryData.dadataInfo.countryIsoCode;
        orderBodyTemplateCopy.orderInfo.postalCode =
          deliveryData.dadataInfo.postalCode;
        orderBodyTemplateCopy.orderInfo.region =
          deliveryData.dadataInfo.region;
        orderBodyTemplateCopy.orderInfo.regionType =
          deliveryData.dadataInfo.regionType;
        orderBodyTemplateCopy.orderInfo.city =
          deliveryData.dadataInfo.city;
        orderBodyTemplateCopy.orderInfo.settlement =
          deliveryData.dadataInfo.settlement;
      }
      (orderBodyTemplateCopy.orderLines as IOrderLine[]) =
        order?.products.map((product) => ({
          productId: product.id,
          quantity: product.quantity || 0,
        }));
      return orderBodyTemplateCopy;
    }, [deliveryData, formatPhoneForOrder, order?.products]);

    const putOrder = useCallback(
      async (url: string, data: IOrderBody) => {
        dispatch(showLoader(true));
        try {
          await httpClient.post(url, data);
          dispatch(setSuccessOrder(true));

          dispatch(
            addAlert({
              text: 'Ваш заказ принят. Счет будет доступен для скачивания в детализации по заказу на странице История.',
              variant: 'success',
            }),
          );

          const orders = await httpClient.get<Order[]>(
            getCreatedOrders(orgId),
          );

          dispatch(setOrders(orders.data));

          metrika.sendPurchase({
            actionFieldID: String(orders?.data?.[0]?.orderId),
            products: [
              ...order.products.map((product) => ({
                id: product.id.toString(),
                name: product.name,
                price: product.price,
                quantity: product.quantity,
              })),
              {
                id: '1',
                name: 'Доставка',
                price:
                  deliveryData.tariff === null
                    ? currency(0)
                    : currency(deliveryData.deliveryPrice),
              },
            ],
          });

          navigate('/orders');
        } catch (error) {
          const err = error as IError;

          switch (err?.response?.status) {
            case 401:
              startOrderOff();
              dispatch(logOutUser());
              break;
            case 400:
              startOrderOff();
              dispatch(
                addAlert({
                  text: 'Часть товаров в корзине закончилась, проверьте пожалуйста корзину',
                  variant: 'danger',
                }),
              );
              break;
            case 409:
              startOrderOff();
              dispatch(
                addAlert({
                  text: 'Ошибка создания заказа, попробуйте еще раз',
                  variant: 'danger',
                }),
              );
              break;
            default:
              startOrderOff();
              dispatch(
                addAlert({
                  text: 'Ошибка создания заказа, попробуйте еще раз',
                  variant: 'danger',
                }),
              );
              break;
          }
        } finally {
          onOrderLoaderFlagToggle(false);
          dispatch(showLoader(false));
        }
      },
      [
        deliveryData.deliveryPrice,
        deliveryData.tariff,
        dispatch,
        navigate,
        onOrderLoaderFlagToggle,
        order,
        startOrderOff,
        orgId,
      ],
    );

    const createOrder = useCallback(async () => {
      dispatch(showLoader(true));
      //   TODO: Временное скрытие оплаты картой. Вернуть когда будет готов payment service
      // dispatch(setShowModalInvoice(true));
      const data = fillOrderBody();

      let url = createOrderUrl(orgId);

      url +=
        deliveryData.deliveryType === 'Доставка'
          ? `?delivery=true&price=${currency(
              deliveryData.deliveryPrice,
            )}`
          : '?delivery=false';

      if (
        (!isEdoFlow && isOfferAccepted) ||
        (isEdoFlow && isEdoRegistered && isOfferAccepted)
      ) {
        dispatch(getProducts()).then(() => putOrder(url, data));
      } else {
        let isByEdo;
        let isEdo;
        let isOffer;

        dispatch(checkDocFlow()).then((checkDocFlowRes) => {
          isByEdo = checkDocFlowRes;
          return dispatch(checkEdo());
        });

        dispatch(checkDocFlow()).then((checkEdoRes) => {
          isEdo = checkEdoRes;
          return dispatch(checkOffer());
        });

        dispatch(checkDocFlow()).then((checkOfferRes) => {
          isOffer = checkOfferRes;
          return true;
        });

        if ((!isByEdo && isOffer) || (isByEdo && isEdo && isOffer)) {
          dispatch(getProducts()).then(() => putOrder(url, data));
          await putOrder(url, data);
        } else {
          dispatch(showModal(true));
        }
      }
      dispatch(showLoader(false));
    }, [
      deliveryData.deliveryPrice,
      deliveryData.deliveryType,
      dispatch,
      fillOrderBody,
      isEdoFlow,
      isEdoRegistered,
      isOfferAccepted,
      putOrder,
      orgId,
    ]);

    const getDelivery = useCallback(
      async (counter: number) => {
        let counterReducer;
        clearTariffs();

        dispatch(setTariffLoading(true));

        const count = order?.products.reduce(
          (sum, current) => sum + current.quantity!,
          0,
        );

        try {
          const { data } = await httpClient.get(
            `${deliveryUrl}?cityId=${deliveryData.fiasId}&count=${count}&cost=${order.totalPrice}`,
          );
          if (!data.length) {
            setAddressError(true);
            setAddressErrorText(
              'Введите другой адрес или ближайший город\n' +
                '(с Вами свяжется оператор)',
            );
          }
          dispatch(
            setDeliveryData({
              ...deliveryData,
              deliveryList: data,
              start: false,
            }),
          );
        } catch (error) {
          const err = error as IError;

          if (err?.response?.status === 401) {
            return dispatch(logOutUser());
          }
          if (counter > 0) {
            counterReducer = counter;
            counterReducer -= 1;
            setTimeout(() => getDelivery(3), 3000, counterReducer);
          }
          dispatch(
            addAlert({
              text: 'Ошибка получения тарифов доставки, попробуйте позже',
              variant: 'danger',
            }),
          );
        } finally {
          dispatch(setTariffLoading(false));
        }
      }, // eslint-disable-next-line
      [
        clearTariffs,
        deliveryData.fiasId,
        deliveryData.deliveryList,
        deliveryData.start,
        dispatch,
        order?.products,
        order?.totalPrice,
      ],
    );

    const handleFieldReset = useCallback(() => {
      dispatch(deliveryPriceReset());
    }, [dispatch]);

    useEffect(() => {
      setListShow(!!list.length && focusAddress);
    }, [list.length, focusAddress]);

    useEffect(() => {
      if (!searchAddress) {
        dispatch(
          setDeliveryData({
            ...deliveryData,
            fiasId: null,
            dadataInfo: null,
          }),
        );
      }

      dispatch(
        setDeliveryData({
          ...deliveryData,
          currentAddress,
          contactName,
          contactPhone,
          comments,
        }),
      );
      // eslint-disable-next-line
    }, [
      searchAddress,
      deliveryData.fiasId,
      deliveryData.dadataInfo,
      currentAddress,
      contactName,
      contactPhone,
      comments,
      dispatch,
    ]);

    useEffect(() => {
      if (
        deliveryData.fiasId &&
        order?.totalPrice &&
        deliveryData.start
      ) {
        getDelivery(3);
      }
    }, [
      deliveryData.start,
      deliveryData.fiasId,
      order?.totalPrice,
      getDelivery,
    ]);

    useEffect(() => {
      const getDadata = async () => {
        setIsLoadingAdres(true);
        try {
          const { data } = await httpClient.get(
            dadataUrl + deliveryData.currentAddress,
          );
          setList(data);
          dispatch(setDeliveryAddressError(false));
        } catch (error) {
          const err = error as IError;

          if (err?.response?.status === 401) {
            return dispatch(logOutUser());
          }
          dispatch(setDeliveryAddressError(true));
        } finally {
          setIsLoadingAdres(false);
        }
      };

      if (
        deliveryData.currentAddress.length >= 3 &&
        (searchAddress || focusAddress)
      ) {
        getDadata();
      } else {
        setList([]);
      }
    }, [
      deliveryData.currentAddress,
      searchAddress,
      focusAddress,
      dispatch,
    ]);

    useEffect(() => {
      if (!startOrder) return;

      clearErrors();

      if (validateOrder()) {
        createOrder();
      }

      onOrderLoaderFlagToggle(false);
      startOrderOff();
    }, [
      startOrder,
      clearErrors,
      createOrder,
      onOrderLoaderFlagToggle,
      startOrderOff,
      validateOrder,
    ]);

    useEffect(() => {
      if (!isDeliveryAddressError) return;
      setAddressError(true);
      setAddressErrorText(
        'Введите другой адрес или ближайший город\n' +
          '(с Вами свяжется оператор)',
      );
    }, [isDeliveryAddressError]);

    useEffect(() => {
      if (isPartner || !deliveryData.deliveryList.length) return;
      const minimalTariffID =
        deliveryData.deliveryList[0].urgentTypeId;
      changeTariff(minimalTariffID);
    }, [isPartner, deliveryData.deliveryList, changeTariff]);

    useEffect(() => {
      if (!isDeliveryFormWasEdit) return;

      const userCheckResult = checkName() && checkPhoneLength();

      const deliveryCheckResult =
        deliveryData.deliveryType === 'Доставка'
          ? checkAddress() && checkTariff()
          : true;

      const res = userCheckResult && deliveryCheckResult;

      dispatch(setDeliveryFormReady(res));
    }, [
      isDeliveryFormWasEdit,
      deliveryData.deliveryType,
      checkAddress,
      checkName,
      checkPhoneLength,
      checkTariff,
      dispatch,
    ]);

    useEffect(() => {
      handleFieldReset();
    }, [handleFieldReset]);

    return (
      <div
        className={
          `${styles['order-delivery']} d-flex flex-column ` +
          ` ${aside ? '' : styles['pl-2']}`
        }
      >
        <div className="head">
          <div className="head pb-4">
            <Row className="align-items-center">
              <Col xs={6}>
                <div className={styles.title}>
                  {deliveryData.deliveryType}
                </div>
              </Col>

              <Col xs={6}>
                <img
                  className={styles['img-size']}
                  src={
                    deliveryData.deliveryType === 'Доставка'
                      ? truckIcon
                      : bagIcon
                  }
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="body flex-md-shrink-1 flex-md-grow-1">
          <div className="switch-tabs">
            <SwitchTabs />
          </div>

          <form action="">
            {isAuth && (
              <div>
                <Row>
                  <Col className="mt-4" xs={12} md={aside ? 12 : 6}>
                    <CustomInput
                      testId="order-del-1"
                      label="Контактное лицо"
                      placeholder={
                        deliveryData.deliveryType === 'Доставка'
                          ? 'Введите ФИО'
                          : 'ФИО или наименование ТК'
                      }
                      theme="edo"
                      type="text"
                      value={contactName}
                      onChange={changeName}
                      error={nameError ? nameErrorText : ''}
                    />
                  </Col>

                  <Col className="mt-4" xs={12} md={aside ? 12 : 6}>
                    <CustomInput
                      testId="order-del-2"
                      label="Контактный телефон"
                      placeholder="Введите номер телефона"
                      legacyMode={false}
                      theme="edo"
                      type="tel"
                      value={contactPhone}
                      maxLength={18}
                      onChange={changePhone}
                      onFocus={handlePhoneFocus}
                      error={phoneError ? phoneErrorText : ''}
                    />
                  </Col>
                </Row>

                {deliveryData.deliveryType === 'Доставка' ? (
                  <div>
                    <Row>
                      <Col className="mt-4 position-relative">
                        <CustomInput
                          testId="order-del-3"
                          label="Адрес доставки"
                          placeholder="Введите адрес"
                          legacyMode={false}
                          theme="edo"
                          type="text"
                          value={currentAddress}
                          onChange={changeCurrentAddress}
                          onFocus={
                            changeCurrentAddressWithoutSearching
                          }
                          onBlur={hideListAfterDelay}
                          isLoading={isLoadingAdres}
                          withClearButton
                          onClear={onClear}
                        />
                        <AutocompleteList
                          show={listShow}
                          list={list}
                          changeCurrentAddressFromSuggest={
                            changeCurrentAddressFromSuggest
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {addressError && addressErrorText && (
                          <div className={styles.error}>
                            {addressErrorText}
                          </div>
                        )}
                      </Col>
                    </Row>

                    {isPartner && (
                      <Row>
                        <Col className="mt-4">
                          <CustomSelect
                            placeholder="Тариф доставки"
                            legacyMode={false}
                            options={deliveryData.deliveryList}
                            disabled={
                              !deliveryData.deliveryList.length
                            }
                            onChange={changeTariff}
                            onReset={handleFieldReset}
                            testId="order-del-1"
                            defValue={
                              deliveryData.tariff?.urgentTypeId || ''
                            }
                            isLoading={isTariffLoading}
                            isClearDelivery={isClearDelivery}
                          />
                          {Boolean(tariffErrorText.length) && (
                            <p className={styles.error}>
                              Выберите тариф
                            </p>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : (
                  <div className="mt-4">
                    <Row className="align-items-end">
                      <Col xs={12} md={aside ? 12 : 6}>
                        <div className={styles['col-name']}>
                          Адрес доставки
                        </div>
                        <div className={styles['col-desc']}>
                          {PICKUP_ADRESS}
                        </div>
                      </Col>

                      <Col
                        xs={12}
                        md={aside ? 12 : 6}
                        className="mt-3 mt-md-0 d-flex justify-content-md-end align-items-baseline"
                      >
                        <Link
                          to="/information#pickup"
                          data-testid="his-order-subitem-link-4"
                          className={styles.link}
                        >
                          Подробнее о самовывозе
                        </Link>

                        <div>
                          <img src={linkIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}

            <div className={styles.block}>
              <div className="mt-3 position-relative">
                <label className={styles.label}>
                  Комментарии к заказу
                </label>

                <textarea
                  data-testid="order-del-textarea-1"
                  className={styles.textarea}
                  onInput={changeComments}
                  value={comments}
                />
              </div>
            </div>
          </form>

          {deliveryData.deliveryType === 'Доставка' && (
            <div
              className={`${styles['items-price']} mt-3 d-none d-md-flex justify-content-between align-items-baseline`}
            >
              <span className={styles['price-delivery']}>
                Цена доставки:
              </span>
              <span className={`${styles.price} ms-3`}>
                {deliveryData.tariff === null
                  ? currency(0)
                  : currency(deliveryData.deliveryPrice)}{' '}
                ₽
              </span>
            </div>
          )}
        </div>
      </div>
    );
  },
);

OrderDelivery.displayName = 'OrderDelivery';

export default OrderDelivery;
